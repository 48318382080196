import React from 'react';
import { Helmet } from 'react-helmet';
import { styled } from '@mui/material';
import Terminal from '@assets/icons/terminal';
import Button from '@ui/Button';
import { ButtonTypes } from '@ui/Button/button-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { quizUserSelector } from '@redux/selectors';
import { navigate } from 'gatsby-link';
import { toggleReceiveEmails } from '@redux/actions';
import QuizLayout from '@components/layouts/quiz-layout';
import { Loader } from '@components/helpers';
import { QuizUser } from '../types';
import { GoogleTagManagerId } from '../../../../../config/env.json';

type ResultProps = {
  readonly quizUser: QuizUser;
  readonly toggleReceiveEmails: (payload: {
    userId: string;
    receiveEmails: boolean;
  }) => void;
};

const Container = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const MainContent = styled('div')`
  max-width: 676px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding-top: 5rem;

  @media (max-width: 960px) {
    padding: 2rem;
  }
`;

const Title = styled('h1')`
  margin: 0;
  color: var(--Pallets-Neutral-700, #484848);
  text-align: center;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 50.4px */
  letter-spacing: -0.42px;

  @media (max-width: 960px) {
    font-size: 24px;
  }
`;

const Course = styled('div')`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 12px;
  background: var(--Pallets-Neutral-15, #f5f5f5);

  div {
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    background: var(--System-White, #fff);
  }

  span {
    color: var(--Pallets-Neutral-700, #484848);
    font-family: 'Space Mono';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
  }
`;

const Info = styled('p')`
  color: var(--Pallets-Neutral-700, #484848);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 36px */

  @media (max-width: 960px) {
    font-size: 16px;
  }
`;

const GetInTouch = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 2rem;

  p {
    color: #667085;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
  }

  a {
    color: var(--Pallets-Neutral-800, #333);
    leading-trim: both;
    text-edge: cap;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 175% */
    text-decoration: none;
  }

  a:hover {
    background-color: transparent;
    text-decoration: underline;
  }
`;

const mapStateToProps = createSelector(
  quizUserSelector,
  (quizUser: QuizUser) => ({
    quizUser
  })
);

const mapDispatchToProps = {
  toggleReceiveEmails
};

const GoogleTagManager = () => {
  return (
    <>
      <Helmet>
        {/* Código do header (GTM Script) */}
        <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GoogleTagManagerId}');
          `}
        </script>
      </Helmet>

      {/* Código do body (GTM noscript) */}
      <noscript>
        <iframe
          title='GTM noscript'
          src={`https://www.googletagmanager.com/ns.html?id=${GoogleTagManagerId}`}
          height='0'
          width='0'
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
    </>
  );
};

function Result({ quizUser, toggleReceiveEmails }: ResultProps): JSX.Element {
  if (!quizUser) {
    void navigate('/quiz');
    return <Loader fullScreen={true} />;
  }

  const handleToggleReceiveEmail = (receiveEmails: boolean) => {
    toggleReceiveEmails({ userId: quizUser.id ?? '', receiveEmails });
  };

  return (
    <QuizLayout>
      <Container>
        <GoogleTagManager />

        {quizUser?.isDrParticipant ? (
          <MainContent>
            <Title>
              Parabéns, você já pode iniciar sua Jornada DEV SENAI com:
            </Title>

            <Course>
              <div>
                <Terminal width={36} height={36} />
              </div>
              <span>
                {quizUser?.registrationQuiz === 1
                  ? 'Fundamentos da programação Front-End'
                  : 'Desenvolvedor Front-End React'}
              </span>
            </Course>

            <Info>
              Clique no botão abaixo para fazer sua matrícula e iniciar seus
              estudos!
            </Info>

            <Button
              style={{ whiteSpace: 'break-spaces' }}
              buttonType={ButtonTypes.Primary}
              href='/quiz/quiz-password'
            >
              FAZER MINHA MATRÍCULA GRÁTIS
            </Button>
          </MainContent>
        ) : (
          <MainContent>
            <Title>Falta pouco...</Title>

            <Info>
              A Jornada DEV SENAI ainda não está disponível na sua região.
              Clique abaixo para ser avisado(a) quando estiver disponível.
            </Info>

            <Button
              style={{ whiteSpace: 'break-spaces' }}
              buttonType={ButtonTypes.Primary}
              onClick={() => handleToggleReceiveEmail(true)}
            >
              ME AVISE QUANDO ESTIVER DISPONÍVEL
            </Button>

            <Button
              style={{ whiteSpace: 'break-spaces', fontSize: '12px' }}
              buttonType={ButtonTypes.Outline}
              onClick={() => handleToggleReceiveEmail(false)}
            >
              Não me avise quando liberar em minha região.
            </Button>
          </MainContent>
        )}

        <GetInTouch>
          <p>Ficou com alguma dúvida? Entre em contato pelo e-mail:</p>
          <a href='mailto:jornada.dev@sc.senai.br'>jornada.dev@sc.senai.br</a>
        </GetInTouch>
      </Container>
    </QuizLayout>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Result);
